import { useEffect } from "react";

interface InitializeSelectedProductParams<T extends { id: string }> {
  searchProductId?: string;
  starredProductId?: string | null;
  myProductsList: T[];
  selectedProduct?: T;
  setSelectedProduct: (product: T) => void;
  selectedProductData?: { product?: T };
}

export function useInitializeSelectedProduct<T extends { id: string }>({
  searchProductId,
  starredProductId,
  myProductsList,
  selectedProduct,
  setSelectedProduct,
  selectedProductData,
}: InitializeSelectedProductParams<T>) {
  useEffect(() => {
    let selected: T | undefined;
    if (searchProductId) {
      // Try to find the product in the list using the product id from the URL.
      selected = myProductsList.find((product) => product.id === searchProductId);
      // If not found and the hook returned data, use the queried product.
      if (!selected && selectedProductData?.product) {
        selected = selectedProductData.product;
      }
    }
    // Fall back to the starred product (if available) or the first product.
    if (!selected) {
      selected = myProductsList.find((product) => product.id === starredProductId) || myProductsList[0];
    }
    if (selected && (!selectedProduct || selected.id !== selectedProduct.id)) {
      setSelectedProduct(selected);
    }
  }, [searchProductId, starredProductId, myProductsList, selectedProduct, setSelectedProduct, selectedProductData]);
}
