import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Form } from "reactstrap";
import { FormGroup, Label } from "../../components/form-group";
import Input from "../../components/Input";
import { Step2Data } from "./wizard";
import { FormHeadingV2 } from "../../components/form-heading";
import { ModalBody } from "@bphxd/ds-core-react/lib/components/modal/ModalBody";
import { ModalFooter } from "../../components/modal-footer";

type Step2FormProps = {
  isSubmitting: boolean;
  onSubmit: (data: Step2Data) => Promise<void>;
  onBackClick: (data: Step2Data) => Promise<void>;
};

export function Step2Form({ isSubmitting, onBackClick, onSubmit }: Step2FormProps) {
  const schemaObject = {
    incident_url: yup.string().url("Must be a valid URL"),
    description: yup.string().required("Required"),
  };
  const schema = yup.object(schemaObject).required();
  const {
    control,
    getValues,
    handleSubmit,
    formState: { isValid },
  } = useForm<Step2Data>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  function handleBackClick() {
    onBackClick(getValues());
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="form-step-2">
      <FormHeadingV2 title="Select product area" currentStep={1} totalSteps={2} />
      <ModalBody>
        <FormGroup>
          <Label htmlFor="incident_url">
            <span>Incident url</span>
            <span className="fw-normal lh-1-75 ms-2 fs-6 text-op-dark-gray-100">optional</span>
          </Label>
          <Controller
            name="incident_url"
            control={control}
            defaultValue={typeof window !== "undefined" ? window?.location?.href : ""}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                id="incident_url"
                data-testid="form-step-3-incident-url"
                trackingName="help-and-support-incident-url"
                makeRoomForError
                invalid={!!error}
                error={error?.message}
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="description">Description</Label>
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                id="description"
                data-testid="form-step-3-description"
                trackingName="help-and-support-description"
                type="textarea"
                style={{ minHeight: "8rem", lineHeight: "unset" }}
                {...field}
                invalid={!!error}
                error={error?.message}
                makeRoomForError
              />
            )}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter
        testIdContext="support-modal-step-2"
        backButtonEventName="support-modal-step-2"
        nextButtonEventName="support-modal-step-2"
        nextButtonText="Submit"
        backButtonText="Previous"
        handleBackClick={handleBackClick}
        showBackButton
        currentStep={2}
        totalSteps={2}
        isNextButtonDisabled={!isValid || isSubmitting}
      />
    </Form>
  );
}
