import { DismissableBanner } from "../components/dismissable-banner";
import React from "react";

export function DevIndicator() {
  if (process.env.NODE_ENV === "development" && process.env.NEXT_PUBLIC_SHOW_BREAKPOINT_INDICATOR === "true") {
    return <div className="breakpoint-indicator"></div>;
  }
  return null;
}
export function Banners() {
  return (
    <div className="d-flex flex-column">
      <DevIndicator />
      <div className="align-self-center">
        <DismissableBanner color={"info"} name={"khub-migration-notice-banner-2"} show={true}>
          Accelerate Documents (Knowledge Hub) have been{" "}
          <a href="https://accelerate.bpglobal.com/knowledgehub/chB6Vb">migrated to Confluence.</a>{" "}
          <strong>
            You can now access the migrated documentation spaces{" "}
            <a href="https://basproducts.atlassian.net/wiki/spaces">here</a>.{" "}
          </strong>
        </DismissableBanner>
      </div>
    </div>
  );
}

export function AxelBanners() {
  return (
    <div className="d-flex flex-column">
      <div className="align-self-center">
        <DismissableBanner color={"info"} name={"axel-migration-notice-banner-2"} show={true}>
          Axel Chat functions will not work if{" "}
          <a href="https://basproducts.atlassian.net/wiki/spaces/DevOpsMethodology/pages/5994250657/How+to+get+started+with+Zscaler">
            Zscaler
          </a>{" "}
          is not enabled on your computer. Please enable Zscaler to use Axel.
        </DismissableBanner>
      </div>
    </div>
  );
}
