import { Fragment } from "react";

export function Notification({
  title,
  relativeDate,
  isRead,
  index,
}: {
  title: string;
  relativeDate: string;
  isRead: boolean;
  index: number;
}) {
  return (
    <Fragment key={index}>
      <div className="notification-message__text d-flex flex-column">
        <div
          className="notification-message__text__title truncate-3-lines mw-100 fw-normal fst-normal"
          style={{
            width: 264,
            fontSize: 13,
            fontStretch: "normal",
            letterSpacing: 0.27,
            lineHeight: "1.62em",
            maxHeight: "4.86em",
            whiteSpace: "initial",
          }}
        >
          {title}
        </div>
        {relativeDate && (
          <div
            className="notification-message__text__date fw-light lh-1-75 "
            style={{ fontSize: 12, letterSpacing: 0.25 }}
          >
            {relativeDate}
          </div>
        )}
      </div>
      <div className="notification-message__indicator h-100 d-flex align-items-center">
        {!isRead && (
          <div
            className="notification-message__indicator__dot rounded-circle bg-info"
            style={{ width: 10, height: 10 }}
          />
        )}
      </div>
    </Fragment>
  );
}
