"use client";
import { useState, Fragment } from "react";
import { CREATE_SUPPORT_TICKET } from "../../api/support/gql";

import { Step1Form } from "./step1";
import SubmissionResults from "./submission-results";
import { useMutation } from "@apollo/client";
import { Step2Form } from "./step2";

export type AccelerateTeam = {
  value: string;
  label: string;
  description: string;
};

export type Step1Data = {
  team: string;
};

export type Step2Data = {
  incident_url: string;
  description: string;
};
export const TEAMS: AccelerateTeam[] = [
  {
    value: "DEVOPS_TOOLING",
    label: "DevOps Tooling",
    description:
      "Issues relating to tooling forms and pages, such as Azure DevOps (ADO), Grafana, Feature Hub or Mix Panel.",
  },
  {
    value: "ACCELERATE_AXEL_SEARCH",
    label: "Axel and Search",
    description: "Issues relating to Accelerate's AI Search functionality.",
  },
  {
    value: "YALLA_INTERNAL_DEVELOPER_PORTAL",
    label: "Yalla Internal Developer Portal",
    description:
      "Issues relating to Yalla functionality including environemnts, services, CI/CD pipelines and deployments.",
  },
  {
    value: "ACCELERATE_PRODUCTS",
    label: "Products",
    description:
      "Issues relating to the products section including creating a product, managing teams resources, repositories and alerts.",
  },
  {
    value: "ACCELERATE_SITE_AND_NAVIGATION",
    label: "Site and Navigation",
    description:
      "Issues relating to the Homepage, Learn and What’s new areas of Accelerate, as well as general issues with the functionality or availability of Accelerate itself.",
  },
];

type HelpAndSupportFormWizardProps = {
  step?: number;
  onStepChange: (step: number) => void;
  onDirty: () => void;
};

export function HelpAndSupportFormWizard({ step = 1, onStepChange, onDirty }: HelpAndSupportFormWizardProps) {
  const [currentStep, setCurrentStep] = useState<number>(step);
  const [step1Data, setStep1Data] = useState<Step1Data>({
    team: "",
  });
  const [step2Data, setStep2Data] = useState<Step2Data>({
    incident_url: "",
    description: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState<string | undefined>();

  const [createSupportTicket] = useMutation(CREATE_SUPPORT_TICKET, {
    onCompleted() {
      goToNextStep();
    },
    onError: handleError,
  });

  function goToNextStep() {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    onStepChange(nextStep);
  }

  async function handleStep1Submit(data: Step1Data) {
    setStep1Data(data);
    setCurrentStep((current) => {
      const skippedStep = current + 1;
      onStepChange(skippedStep);
      return skippedStep;
    });
  }

  async function handleStep3Back(data: Step2Data) {
    setStep2Data(data);
    setCurrentStep((current) => {
      const skippedBackStep = current - 1;
      onStepChange(skippedBackStep);
      return skippedBackStep;
    });
  }

  async function handleStep3Submit(data: Step2Data) {
    setStep2Data(data);

    const { description, incident_url } = data;
    const service_name = step1Data?.team;
    setIsSubmitting(true);

    const step3DataVariables = {
      description,
      incidentUrl: incident_url,
      serviceName: service_name,
      supportType: "INCIDENT",
    };

    await createSupportTicket({ variables: step3DataVariables });

    setIsSubmitting(false);
  }

  function handleError(error: { message: string }) {
    setIsSubmitting(false);
    setRequestError(`Error: ${error.message}`);
    goToNextStep();
  }

  return (
    <Fragment>
      <div style={{ display: currentStep === 1 ? "block" : "none" }}>
        <Step1Form
          {...step1Data}
          teamOptions={TEAMS}
          onDirty={onDirty}
          onSubmit={handleStep1Submit}
          isSubmitting={isSubmitting}
        />
      </div>
      <div style={{ display: currentStep === 2 ? "block" : "none" }}>
        <Step2Form
          {...step2Data}
          onSubmit={handleStep3Submit}
          onBackClick={handleStep3Back}
          isSubmitting={isSubmitting}
        />
      </div>

      <div style={{ display: currentStep === 3 ? "block" : "none" }}>
        <SubmissionResults failure={!!requestError} />
      </div>
    </Fragment>
  );
}
