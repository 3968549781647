"use client";

import { useUser } from "../../hooks/use-user";
import { Avatar } from "@bphxd/ds-core-react";
import { getInitials } from "../../utils/user-initials";
export const UserAvatar = () => {
  const user = useUser();

  return (
    <Avatar
      aria-label="avatar"
      image={`${user?.photo ? `data:image/jpeg;base64, ${user?.photo}` : ""}`}
      initials={getInitials(`${user?.givenName} ${user?.surname}`)}
    />
  );
};
