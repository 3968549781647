import { ComponentPropsWithoutRef, MouseEvent, ReactNode } from "react";
import { UrlObject } from "url";
import NextLink from "next/link";
import { ExternalLink16 } from "@bphxd/ds-core-react/lib/icons";

import { trackEvent } from "../utils/event-tracker";

export function isUrlInternal(href: string) {
  return /^[/?#]/.test(makeUrlInternal(href));
}

function makeUrlInternal(href: string) {
  let target = href;
  if (window?.location?.origin) {
    target = href.replace(window.location.origin, "");
  }

  return target;
}

type LinkProps = Omit<ComponentPropsWithoutRef<"a">, "href"> & {
  href: string | UrlObject | undefined;
  name?: string;
  hideExternalLinkIcon?: boolean;
  doNotTrack?: boolean;
  dataTestid?: string;
  children: ReactNode;
  trackingEventProps?: Record<string, any>;
};

export function Link({
  href,
  className = "",
  name = "",
  hideExternalLinkIcon = false,
  style = {},
  onClick = () => {},
  doNotTrack = false,
  tabIndex = 0,
  dataTestid = "",
  children,
  trackingEventProps,
  ...rest
}: LinkProps) {
  if (!href) {
    console.error({
      message: "anchor tag is missing href",
      dataTestid,
      ...rest,
    });
    return null;
  }

  function handleClick(e: MouseEvent<HTMLAnchorElement>) {
    if (doNotTrack) {
      return onClick(e);
    }
    if (name || trackingEventProps?.name) {
      trackEvent({ name: `link_${name}_click`, ...trackingEventProps });
    }
    onClick(e);
  }

  if (typeof href === "object") {
    return (
      <NextLink
        href={href}
        style={style}
        className={className}
        data-testid={dataTestid || undefined}
        onClick={handleClick}
        {...rest}
      >
        {children}
      </NextLink>
    );
  }

  // Handle internal URLs
  return isUrlInternal(href) ? (
    <NextLink
      href={makeUrlInternal(href)}
      style={style}
      className={className}
      data-testid={dataTestid || undefined}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </NextLink>
  ) : (
    <a
      href={href}
      tabIndex={tabIndex || undefined}
      target="_blank"
      rel="noreferrer"
      style={style}
      data-testid={dataTestid || undefined}
      className={className}
      onClick={handleClick}
      {...rest}
    >
      {hideExternalLinkIcon ? (
        children
      ) : (
        <div className="d-flex align-items-center">
          <div>{children}</div>
          <ExternalLink16 data-testid="external-link-icon" className="ms-2" />
        </div>
      )}
    </a>
  );
}
