"use client";
import { useEffect } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Form } from "reactstrap";
import { FormGroup } from "../../components/form-group";
import Input, { InputErrorMessage } from "../../components/Input";
import { AccelerateTeam, Step1Data } from "./wizard";
import { FormHeadingV2 } from "../../components/form-heading";
import { ModalBody } from "@bphxd/ds-core-react/lib/components/modal/ModalBody";
import { ModalFooter } from "../../components/modal-footer";

type Step1FormProps = {
  teamOptions: AccelerateTeam[];
  isSubmitting: boolean;
  onSubmit: SubmitHandler<Step1Data>;
  onDirty: () => void;
};

export function Step1Form({ teamOptions, isSubmitting, onSubmit, onDirty, ...data }: Step1FormProps) {
  const schemaObject = {
    team: yup.string().required("Required"),
  };
  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<Step1Data>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: data,
  });

  useEffect(() => {
    const subscription = watch(() => {
      onDirty();
    });
    return () => subscription.unsubscribe();
  }, [watch, onDirty]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="form-step-1">
      <FormHeadingV2 title="Select product area" currentStep={1} totalSteps={2} />
      <ModalBody>
        <Controller
          name="team"
          control={control}
          render={({ field }) => (
            <>
              {teamOptions.map(({ value, description, label }) => (
                <FormGroup check key={value}>
                  <Input
                    {...field}
                    id={`team-${value}`}
                    data-testid={`form-step-1-radio-${value}`}
                    trackingName="help-and-support-team"
                    type="radio"
                    value={value}
                    checked={value === control._formValues.team}
                  />
                  <label htmlFor={`team-${value}`}>
                    <span className="font-normal">{label}</span>
                    <p className="small fw-light mb-0">{description}</p>
                  </label>
                </FormGroup>
              ))}
            </>
          )}
        />
        <InputErrorMessage>{errors.root?.message}</InputErrorMessage>
      </ModalBody>
      <ModalFooter
        testIdContext="support-modal-step-1"
        backButtonEventName="support-modal-step-1"
        nextButtonEventName="support-modal-step-1"
        nextButtonText="Next"
        currentStep={1}
        totalSteps={2}
        isNextButtonDisabled={!isValid}
      />
    </Form>
  );
}
