import * as React from "react";
import { SVGProps } from "react";
const Dark = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" aria-hidden="true" {...props}>
    <g fill="currentColor" clipPath="url(#a)">
      <path d="M7.5.5a.5.5 0 0 0-1 0V2a.5.5 0 0 0 1 0V.5z" />
      <path
        fillRule="evenodd"
        d="M7 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-1a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        clipRule="evenodd"
      />
      <path d="M7 11.5a.5.5 0 0 1 .5.5v1.5a.5.5 0 0 1-1 0V12a.5.5 0 0 1 .5-.5zM11.5 7a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm-11-.5a.5.5 0 0 0 0 1H2a.5.5 0 0 0 0-1H.5zm3.318 3.682a.5.5 0 0 1 0 .707l-1.06 1.06a.5.5 0 1 1-.708-.706l1.06-1.06a.5.5 0 0 1 .708 0zm8.132-7.425a.5.5 0 1 0-.707-.707l-1.061 1.061a.5.5 0 1 0 .707.707l1.06-1.06zm-1.768 7.425a.5.5 0 0 1 .707 0l1.06 1.06a.5.5 0 1 1-.706.708l-1.061-1.06a.5.5 0 0 1 0-.708zM2.757 2.05a.5.5 0 1 0-.707.707l1.06 1.061a.5.5 0 0 0 .708-.707l-1.06-1.06z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Dark;
