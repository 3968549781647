"use client";
import { useMemo } from "react";
import { ApolloClient, from, HttpLink, InMemoryCache, split } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { requestAccessToken } from "../utils/helpers/msal-helper";
import { setContext } from "@apollo/client/link/context";
import { ACCELERATE_APIS } from "../api/config";
import { env } from "next-runtime-env";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";

import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

if (process.env.NODE_ENV !== "production") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient;

export function addAzureAxelRoutingLink(client, routingKey) {
  const withGlobalContext = setContext((_, previousContext) => {
    if (!("routingKey" in previousContext)) {
      return {
        ...previousContext,
        routingKey: routingKey,
      };
    }
    return previousContext;
  });

  client.setLink(from([withGlobalContext, client.link]));
}

export function createApolloClient(clientToken) {
  const isBrowser = typeof window !== "undefined";

  // Http Link
  const httpLink = new HttpLink({
    uri: env("NEXT_PUBLIC_APOLLO_URL"),
    credentials: "same-origin",
    headers: {
      "Apollo-Require-Preflight": "true",
      "client-name": `Web App [web]`,
      "client-version": `1.0.0`,
      lang: "en",
      Accept: "multipart/mixed; deferSpec=20220824, application/json",
    },
  });

  const azureAxelHttpLink = new HttpLink({
    uri: env("AXEL_AZURE_URL") || "https://aisearch.bpweb.bp.com/graphql",
    credentials: "include",
    headers: {
      "Apollo-Require-Preflight": "true",
      "client-name": `Web App [web]`,
      "client-version": `1.0.0`,
      lang: "en",
      Accept: "multipart/mixed; deferSpec=20220824, application/json",
    },
  });

  // Use split to choose link based on context or operation name
  const link = split(
    ({ operationName, getContext }) => {
      const { routingKey } = getContext();
      // The only case when azure axel link will be used is when the routingKey is "azure-axel"
      if (routingKey === "azure-axel") {
        return (
          operationName === "ListChats" ||
          operationName === "OpenChat" ||
          operationName === "ChatRequest" ||
          operationName === "RenameChat" ||
          operationName === "DeleteChat" ||
          operationName === "NewChat" ||
          operationName === "SubmitAxelIssue"
        );
      }
      return false;
    },
    azureAxelHttpLink,
    httpLink,
  );

  let withToken;
  withToken = setContext(async (_, { headers }) => {
    let Authorization;
    if (clientToken) {
      Authorization = `Bearer ${clientToken}`;
    } else {
      const token = await requestAccessToken(ACCELERATE_APIS.apollo.scopes);
      Authorization = token ? `Bearer ${token}` : null;
    }
    return {
      headers: {
        ...headers,
        Authorization,
      },
    };
  });

  // Error Link
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, extensions:${extensions?.code} Location: ${locations}, Path: ${path}`,
        ),
      );
    }
    if (networkError) {
      try {
        JSON.parse(networkError.bodyText);
      } catch (e) {
        console.error(e);
        if (networkError.bodyText) {
          networkError.message = networkError.bodyText;
          console.log(networkError.message);
        }
      }
      console.log(`[Network error]: ${networkError}`);
    }
    return forward(operation);
  });

  // Cache Policy
  const cache = new InMemoryCache({
    typePolicies: {
      YallaPlatform: { keyFields: ["name"] },
      CapabilityResult: { keyFields: ["productId"] },
      L3EntityResponse: { keyFields: ["serviceNowId"] },
      SupportGroup: { keyFields: ["opsGroupId"] },
    },
  });

  return new ApolloClient({
    ssrMode: !isBrowser,
    link: from([errorLink, withToken, link]),
    cache: cache,
    credentials: "include",
  });
}

export function initializeApollo(initialState = null, clientToken) {
  const _apolloClient = apolloClient ?? createApolloClient(clientToken);
  // If your page has Next.js data fetching methods that use Apollo Client,
  //  the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps
    // in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });
    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }
  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }
  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME];
  const client = useMemo(() => initializeApollo(state), [state]);

  return client;
}
