"use client";
import React, { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ExceptionPage from "../views/exception/exception-page";
import { useLocation } from "../hooks/use-location";
import { useAppActions, useToasts } from "../hooks/use-app-store";

export function Main({ children }: PropsWithChildren) {
  const location = useLocation();
  const toasts = useToasts();
  const { setIsHelpAndSupportModalOpen } = useAppActions();

  const errorHandler = (error: any, info: any) => {
    console.error({ error, info });
  };

  return (
    <main className="py-0 mt-n10 flex-grow-1" role="main">
      <ErrorBoundary
        key={location.pathname}
        fallbackRender={() => <ExceptionPage setIsHelpAndSupportModalOpen={setIsHelpAndSupportModalOpen} />}
        onError={errorHandler}
      >
        {children}
      </ErrorBoundary>

      {/* If you have any toasts to display, render them here */}
      {toasts.length > 0 && <div style={{ position: "absolute", bottom: 0, right: "2rem" }}>{toasts}</div>}
    </main>
  );
}
