import { useApolloClient, useQuery } from "@apollo/client";
import { LIST_CHATS } from "../../../api/axel/gql";
import { addAzureAxelRoutingLink } from "../../../apollo/config";

export function useAxelChats(useAzure: boolean = false) {
  const client = useApolloClient();
  if (useAzure) {
    addAzureAxelRoutingLink(client, "azure-axel");
  }

  const {
    loading: chatsAreLoading,
    refetch: refetchChats,
    data: chatListData,
    error,
  } = useQuery(LIST_CHATS, {
    fetchPolicy: "network-only",
  });

  return {
    chats: chatListData?.listChats ?? [],
    chatsAreLoading,
    refetchChats: refetchChats as () => void,
    error,
  };
}
