"use client";
import React, { PropsWithChildren } from "react";
import { SideMegaMenu } from "../navigation/mega-menu/side-mega-menu";
import { Header } from "../components/header";
import { Banners } from "./banners";
import { Main } from "./main";
import { Footer } from "../components/footer";

export const PageLayout: React.FC<PropsWithChildren> = (props) => {
  return (
    <div>
      <div className="d-flex overflow-hidden vh-100">
        <SideMegaMenu />
        <div className="d-flex flex-column flex-grow-1 overflow-y-auto min-vh-100">
          <Header />
          <Banners />
          <Main>{props.children}</Main>
          <Footer />
        </div>
      </div>
    </div>
  );
};
