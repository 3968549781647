import { UserAvatar } from "../navigation/mega-menu/user-avatar";
import { NotificationTray } from "./notification-tray";
import React from "react";
import { PageSection } from "./page-template";
import { Col, Row } from "reactstrap";
import { DarkMode } from "./dark-mode";

export function Header() {
  return (
    <PageSection>
      <Row>
        <Col xs="12">
          <header className="d-flex pt-3 d-flex align-items-end  flex-column-reverse">
            <div className="d-flex">
              <NotificationTray />
              <DarkMode />
              <UserAvatar />
            </div>
          </header>
        </Col>
      </Row>
    </PageSection>
  );
}
