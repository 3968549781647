"use client";
import { useEffect, useState, useRef, useCallback } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { Socket, io } from "socket.io-client";
import { requestAccessToken } from "../utils/helpers/msal-helper";
import { ACCELERATE_APIS } from "../api/config";

export function useWebsocket(namespace: `/${string}` = "/"): Socket | undefined {
  const isAuthenticated = useIsAuthenticated();
  const [token, setToken] = useState<string>();
  const socketRef = useRef<Socket | null>(null);

  const { url, scopes } = ACCELERATE_APIS.notifications;
  const namespacedUrl = new URL(namespace, url!).toString();

  // useCallback to memoize getToken function
  const getToken = useCallback(async () => {
    if (!isAuthenticated) return;
    const bearerToken = await requestAccessToken(scopes);
    if (bearerToken) {
      setToken(bearerToken);
    }
  }, [isAuthenticated, scopes]);

  useEffect(() => {
    if (!namespacedUrl || !token) return; // Ensure URL and token are available
    if (socketRef.current) return; // Avoid reinitialization

    const socket = io(namespacedUrl, {
      transports: ["websocket"],
      autoConnect: true,
      query: { token },
      auth: { token },
    });

    socket.on("connect", () => console.log(`${namespace} websocket connected`));
    socket.on("connect_error", (e) => console.log(`${namespace} websocket error`, e));
    socket.on("disconnect", () => console.log(`${namespace} websocket disconnected`));
    socket.onAny((event, ...args) => console.log(`${namespace} received event:`, event, args));

    socketRef.current = socket;

    return () => {
      console.log(`${namespace} cleaning up websocket`);
      socket.close();
      socketRef.current = null;
    };
  }, [namespace, token, namespacedUrl]);

  useEffect(() => {
    if (!namespacedUrl) {
      return;
    }
    if (!isAuthenticated) {
      return;
    }
    getToken();
  }, [isAuthenticated, getToken]);

  return socketRef.current ?? undefined;
}
