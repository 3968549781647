import { useEffect, useRef, useState, ReactNode } from "react";
import { usePathname } from "next/navigation";
import { Progress } from "reactstrap";

type NextProgressBarProps = {
  children: ReactNode;
};

export default function ProgressBar({ children }: NextProgressBarProps) {
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const pathname = usePathname();
  const prevPathname = useRef(pathname);

  useEffect(() => {
    // Detect route change by comparing current pathname with previous
    if (pathname !== prevPathname.current) {
      prevPathname.current = pathname;
      setIsLoading(true);
      setProgress(30);
      const timer = setInterval(() => {
        setProgress((prev) => Math.min(prev + 10, 90));
      }, 200);

      const completeTimer = setTimeout(() => {
        clearInterval(timer);
        setProgress(100);
        setTimeout(() => {
          setIsLoading(false);
          setProgress(0);
        }, 300);
      }, 1000);

      return () => {
        clearInterval(timer);
        clearTimeout(completeTimer);
      };
    }
  }, [pathname]);

  return (
    <>
      {isLoading && (
        <Progress
          value={progress}
          style={{ color: "#0d6efd", height: "5px" }}
          className="bg-success top-0 w-100 position-fixed h-1px"
        />
      )}
      {children}
    </>
  );
}
