import * as React from "react";
import { SVGProps } from "react";
const Book = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <title>{"Light / Iconography / Accelerate / Book / Light / 24"}</title>
    <g fill="none" fillRule="evenodd" stroke="#111">
      <path stroke="currentColor" d="M6.16 6.078v9.071L12 16.312l5.84-1.163V6.08L12 7.095zM12 7.096v9.216" />
      <path stroke="currentColor" d="M4.32 6.078V16.65L12 18.078l7.68-1.428V6.078" />
    </g>
  </svg>
);
export default Book;
