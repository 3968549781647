import { useMemo, useState } from "react";
import { DropdownItem } from "reactstrap";
import { BulletList24, Select24 } from "@bphxd/ds-core-react/lib/icons/index";
import { useProductContext } from "../../providers/product-provider";
import { DropdownMenu } from "../../components/dropdown-menu";
import { useLocation } from "../../hooks/use-location";
import { LinkButton } from "../../components/link-button";
import { Link } from "../../components/Link";
import { ProductFavorite } from "./product-favorite";
import { useSelectedProduct } from "../profile/hooks/use-selected-product";
import { useInitializeSelectedProduct } from "../profile/hooks/use-initialize-selected-product";

function TruncatedText({ text }: { text?: string }) {
  const truncated = useMemo(() => {
    const MAX_LENGTH = 18;
    return text && text.length > MAX_LENGTH ? `${text.substring(0, MAX_LENGTH)}...` : text;
  }, [text]);
  return <>{truncated}</>;
}

export function ProductDropdown() {
  const { myProductsList, selectedProduct, setSelectedProduct, starredProductId } = useProductContext();
  const { search } = useLocation();
  const [hoveredProductId, setHoveredProductId] = useState<string | null>(null);

  // Use the hook to fetch a product by the URL product_id. this hook is skipped if productId is ""
  const { data: selectedProductData } = useSelectedProduct({ productId: (search.product_id as string) || "" });

  // Use the custom hook to initialize the selected product.
  useInitializeSelectedProduct({
    searchProductId: search.product_id as string,
    starredProductId,
    myProductsList,
    selectedProduct,
    setSelectedProduct,
    selectedProductData,
  });

  function isFavorite(id: string) {
    return starredProductId === id;
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Link
        title={selectedProduct?.name}
        className="nav-link p-0"
        href={`/profile/product/${selectedProduct?.id}/?tab=1-overview`}
      >
        <TruncatedText text={selectedProduct?.name} />
      </Link>
      <DropdownMenu toggle={<Select24 />} trackingEventProps={{ name: "mega-menu-select-product-dropdown" }}>
        {myProductsList.map((product) => (
          <DropdownItem
            key={product.id}
            onMouseEnter={() => setHoveredProductId(product.id)}
            onMouseLeave={() => setHoveredProductId(null)}
            className={`w-100 ${isFavorite(product.id) ? "border-bottom " : ""}`}
          >
            <div className="d-flex align-items-center justify-content-between w-100 overflow-hidden">
              <LinkButton
                title={product.name}
                href={`/profile/product/${product.id}?tab=1-overview`}
                className="dropdown-item flex-grow-1 text-truncate ps-0"
                onClick={() => setSelectedProduct(product)}
                name={product.name}
                level="quartenary"
                rounded="circle"
                size="sm"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "220px",
                }}
              >
                <TruncatedText text={product?.name} />
              </LinkButton>
              {isFavorite(product.id) && <ProductFavorite productId={product.id} />}
              {!isFavorite(product.id) ? (
                <div className="ms-auto" style={{ visibility: hoveredProductId === product.id ? "visible" : "hidden" }}>
                  <ProductFavorite productId={hoveredProductId} />
                </div>
              ) : null}
            </div>
          </DropdownItem>
        ))}
        <DropdownItem className="border-top">
          <LinkButton
            href="/all-products"
            className="dropdown-item ps-0"
            Icon={BulletList24}
            name="All-products"
            level="quartenary"
            rounded="circle"
            size="sm"
          >
            All products
          </LinkButton>
        </DropdownItem>
      </DropdownMenu>
    </div>
  );
}
