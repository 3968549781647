import { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { trackEvent } from "../utils/event-tracker";

import { DropdownMenu as DM, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { Input } from "@bphxd/ds-core-react";
import { parseAsStringEnum, useQueryState } from "nuqs";

export function DropdownMenu({
  trackingEventProps,
  toggle,
  children,
  style = {},
  direction = "down",
}: PropsWithChildren<{
  size?: string;
  style?: CSSProperties;
  toggle?: string | ReactNode;
  trackingEventProps: any;
  direction?: "up" | "down" | "start" | "end";
}>) {
  return (
    <UncontrolledButtonDropdown direction={direction}>
      <DropdownToggle
        style={style}
        color="tertiary"
        className="p-0"
        data-testid="dropdown-toggle"
        onClick={() => {
          trackEvent(trackingEventProps);
        }}
        size="xs"
        caret
      >
        {toggle}
      </DropdownToggle>
      <DM>{children}</DM>
    </UncontrolledButtonDropdown>
  );
}

type SortByDropdownProps<T> = {
  sortFields: Map<T, string>;
  currentField: T;
};

export function SortByDropdown<T>({ sortFields, currentField }: SortByDropdownProps<T>) {
  const [, setSortBy] = useQueryState("sort_by", parseAsStringEnum([...sortFields.keys()] as string[]));

  const handleSelectChange = (event: any) => {
    const selectedKey = event.target.value;
    trackEvent({ name: "sort_items", from: currentField, to: selectedKey });
    setSortBy(selectedKey);
  };

  return (
    <Input
      className="bg-primary"
      type="select"
      name="select-services-card-sort-by"
      data-testid="select-services-sort"
      value={currentField as unknown as string}
      onChange={handleSelectChange}
    >
      {Array.from(sortFields).map(([key, value]) => (
        <option key={key as unknown as string} value={key as unknown as string}>
          Sort by {value.toLowerCase()}
        </option>
      ))}
    </Input>
  );
}
