import { useEffect, useState } from "react";
import Light from "../icons/light";
import Dark from "../icons/dark";
import { Button } from "reactstrap";

export enum Theme {
  Dark = "dark",
  Light = "light",
}

// Helper function to determine the initial theme.
// This function checks localStorage first, and if no value is stored,
// it falls back to the system preference.
function getInitialTheme(): boolean {
  if (typeof window !== "undefined") {
    const storedTheme = localStorage.getItem("theme") as Theme | null;
    if (storedTheme) {
      return storedTheme === Theme.Dark;
    }
    // Fall back to system preference
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  }
  return false; // Default to light mode on the server
}

export function DarkMode() {
  // Initialize state with the computed initial theme
  const [isDarkMode, setIsDarkMode] = useState(getInitialTheme);

  // Single effect that runs whenever the dark mode state changes
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add(Theme.Dark);
    } else {
      document.documentElement.classList.remove(Theme.Dark);
    }
    localStorage.setItem("theme", isDarkMode ? Theme.Dark : Theme.Light);
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  return (
    <Button
      name="dark-mode"
      style={{ width: 42, height: 42 }}
      className="z-1 d-flex p-0 align-items-center border-0 justify-content-center rounded-circle cursor-pointer x5-me-2 btn-standard-tertiary bg-quinary"
      onClick={toggleDarkMode}
    >
      {isDarkMode ? <Dark /> : <Light />}
    </Button>
  );
}
