import { ProductType } from "../../../../providers/product-provider";

/**
 * Filters the products by checking if the user is a member,
 * sorts them alphabetically by name, and if a starredProductId is provided,
 * moves that product to the top.
 */
export function sortAndPrioritizeProducts(
  products: ProductType[],
  userEmail: string | undefined | null,
  starredProductId?: string | null,
): ProductType[] {
  const filtered = products.filter(
    (product) => userEmail && product.memberIds.some((id) => id.toLowerCase() === userEmail.toLowerCase()),
  );
  const sorted = [...filtered].sort((a, b) => a.name.localeCompare(b.name));

  if (!starredProductId) return sorted;

  const starredIndex = sorted.findIndex((product) => product.id === starredProductId);
  if (starredIndex === -1) return sorted;

  const starredProduct = sorted[starredIndex];
  const rest = sorted.filter((_, idx) => idx !== starredIndex);
  return [starredProduct, ...rest];
}
