import { gql } from "@apollo/client";
import { graphql } from "../../gql";

export const BasicProductFields = /* GraphQL */ `
  fragment BasicProductFields on ProductResponse {
    id
    name
    description
    permissions {
      edit
      delete
      linkage
    }
    memberIds
    ownerId
    designLeadId
    techLeadId
    delegates {
      email
    }
    l3EntityUid
    yallaProductName
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($deleteProductId: String!) {
    deleteProduct(id: $deleteProductId) {
      isDeleted
    }
  }
`;

export const GET_USER_L3_ENTITIES = gql`
  query GetUserL3Entities {
    userL3Entities {
      serviceNowId
      name
      tier {
        name
        parentName
      }
    }
  }
`;

export const SEARCH_L3_ENTITIES = gql`
  query SearchL3Entities($search: String!, $pageSize: Int, $page: Int!) {
    searchL3Entities(search: $search, pageSize: $pageSize, page: $page) {
      serviceNowId
      name
      tier {
        name
        parentName
      }
    }
  }
`;

export const ALERT_GROUP_FIELDS = /* GraphQL */ `
  fragment UserFields on User {
    email
    givenName
    surname
    jobTitle
    userId
    photo
    contact {
      email
      slack
      teams
    }
  }
  fragment AlertFields on Alert {
    id
    dateRaised
    dateUpdated
    alertStatus
    description
    resolutionNote
    ... @defer {
      raisedBy {
        ...UserFields
      }
    }
  }
`;

export const DELETE_ALERT = gql`
  mutation DeleteAlert($deleteAlertId: String!) {
    deleteAlert(id: $deleteAlertId) {
      isDeleted
    }
  }
`;

export const GET_ACCELERATE_PRODUCTS = graphql(/* GraphQL */ `
  query GetAccelerateProducts {
    currentUserProfile {
      products {
        ...BasicProductFields
      }
    }
  }
`);

export const GET_L3_ENTITY = graphql(/* GraphQL */ `
  query GetL3Entity($id: String!) {
    l3Entity(id: $id) {
      serviceNowId
      name
      tier {
        name
        parentName
      }
      description
      opsGroups {
        id
        name
        users {
          email
          givenName
          surname
          jobTitle
          updatedAt
          userId
        }
        supportGroup {
          name
        }
      }
    }
  }
`);

export const CREATE_ACCELERATE_PRODUCT = graphql(/* GraphQL */ `
  mutation CreateAccelerateProduct($name: String!, $l3Uid: String!, $description: String!) {
    createProduct(name: $name, l3Uid: $l3Uid, description: $description) {
      id
      name
      description
      l3Entity {
        serviceNowId
        name
      }
      memberIds
      ownerId
      techLeadId
      designLeadId
      delegates {
        username
      }
    }
  }
`);
export const EDIT_ACCELERATE_PRODUCT = graphql(`
  mutation EditAccelerateProduct(
    $id: String!
    $name: String
    $description: String
    $l3Uid: String
    $repositories: [EditProductRepositoriesInput!]
    $yallaProductName: String
  ) {
    editProduct(
      id: $id
      name: $name
      description: $description
      l3Uid: $l3Uid
      repositories: $repositories
      yallaProductName: $yallaProductName
    ) {
      id
      name
      description
      l3Entity {
        name
        serviceNowId
      }
      memberIds
    }
  }
`);

export const CREATE_PRODUCT_LINKED_RESOURCE = gql`
  mutation createProductLinkedResource($productId: String!, $description: String!, $url: String!) {
    createProductLinkedResource(productId: $productId, description: $description, url: $url) {
      description
      url
    }
  }
`;

export const DELETE_PRODUCT_LINKED_RESOURCE = gql`
  mutation deleteProductLinkedResource($id: String!) {
    deleteProductLinkedResource(id: $id) {
      isDeleted
    }
  }
`;

export const UPDATE_PRODUCT_LINKED_RESOURCE = gql`
  mutation updateProductLinkedResource($description: String!, $id: String!, $url: String!) {
    updateProductLinkedResource(description: $description, id: $id, url: $url) {
      id
      description
      url
    }
  }
`;

export const GET_USER_PRODUCTS = graphql(/* GraphQL */ `
  query UserProducts {
    currentUserProfile {
      products {
        id
        name
        description
        memberIds
        ownerId
        delegates {
          email
        }
        techLeadId
        permissions {
          edit
          delete
          linkage
        }
        l3EntityUid
      }
    }
  }
`);

export const GET_PRODUCT_BY_ID = graphql(/* GraphQL */ `
  query ProductById($productId: String!) {
    product(id: $productId) {
      id
      name
      description
      memberIds
      ownerId
      delegates {
        email
      }
      techLeadId
      permissions {
        edit
        delete
        linkage
      }
      l3EntityUid
    }
  }
`);

export const SEARCH_PRODUCTS_BY_NAME = graphql(/* GraphQL */ `
  query SearchProducts($search: String, $skip: Int, $limit: Int) {
    productsPaginated(search: $search, skip: $skip, limit: $limit) {
      total
      items {
        ...BasicProductFields
      }
    }
  }
`);

/* GraphQL */ `
  fragment SupportGroupMutationFields on SupportGroup {
    opsGroupId
    productId
    name
    description
    contactMethod {
      type
      value
    }
    users {
      email
      givenName
      surname
      userId
    }
  }
`;
export const CREATE_SUPPORT_GROUP = graphql(/* GraphQL */ `
  mutation CreateSupportGroup($data: SupportGroupCreateArgs!) {
    createSupportGroup(data: $data) {
      ...SupportGroupMutationFields
    }
  }
`);
export const UPDATE_SUPPORT_GROUP = graphql(/* GraphQL */ `
  mutation UpdateSupportGroup($data: SupportGroupUpdateArgs!) {
    updateSupportGroup(data: $data) {
      ...SupportGroupMutationFields
    }
  }
`);

export const DELETE_SUPPORT_GROUP = graphql(/* GraphQL */ `
  mutation DeleteSupportGroup($data: SupportGroupDeleteArgs!) {
    deleteSupportGroup(data: $data)
  }
`);
export const COMPLETE_SUPPORT_GROUP_DELETION = graphql(/* GraphQL */ `
  mutation CompleteSupportGroupDeletion($opsGroupId: String!) {
    completeSupportGroupDeletion(opsGroupId: $opsGroupId)
  }
`);

export const CREATE_SUPPORT_ALERT = gql`
  ${ALERT_GROUP_FIELDS}
  mutation CreateAlert($data: AlertCreateArgs!) {
    createAlert(data: $data) {
      ...AlertFields
      supportGroup {
        opsGroupId
        name
      }
    }
  }
`;

export const GET_REPO_INFO = gql`
  query GetRepoInfo($repositoryId: String!) {
    getRepoInfo(repositoryId: $repositoryId) {
      repositoryId
      repositoryName
      projectId
      projectName
      organisationName
    }
  }
`;

export const GET_ADO_REPOS_IN_PROJECT = gql`
  query GetAllActiveADORepos($organisationName: String!, $projectName: String!) {
    getAllActiveADORepos(organisationName: $organisationName, projectName: $projectName) {
      id
      isDisabled
      name
    }
  }
`;

export const ADD_PRODUCT_TEAM_MEMBERS = graphql(/* GraphQL */ `
  mutation AddProductMembers($memberEmails: [String!]!, $productId: String!) {
    addProductMembers(memberEmails: $memberEmails, productId: $productId) {
      id
      name
    }
  }
`);

export const REMOVE_PRODUCT_TEAM_MEMBERS = graphql(/* GraphQL */ `
  mutation RemoveProductMembers($memberEmails: [String!]!, $productId: String!) {
    removeProductMembers(memberEmails: $memberEmails, productId: $productId) {
      id
      name
    }
  }
`);

export const LEAVE_PRODUCT = graphql(/* GraphQL */ `
  mutation LeaveProduct($productId: String!) {
    leaveProduct(productId: $productId) {
      id
      name
      memberIds
    }
  }
`);
export const JOIN_PRODUCT = graphql(/* GraphQL */ `
  mutation JoinProduct($productId: String!) {
    joinProduct(productId: $productId) {
      id
      name
      memberIds
    }
  }
`);

export const FAVORITE_PRODUCT = graphql(/* GraphQL */ `
  mutation SetStarredProduct($productId: String) {
    setStarredProduct(productId: $productId) {
      _id
    }
  }
`);
