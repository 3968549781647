import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "../../components/button";
import { Close } from "@bphxd/ds-core-react";
import { textToSlug } from "../../utils/helpers/utils";
import { PropsWithChildren } from "react";
import { Rocket48 } from "@bphxd/ds-core-react/lib/icons/index";
import { Check16 } from "@bphxd/ds-core-react/lib/icons";

export type SuccessModalProps = PropsWithChildren<{
  isOpen: boolean;
  toggle: () => void;
  title?: string;
  message?: string;
  closeButtonText?: string;
  showConfirmationButton?: boolean;
  confirmationButtonText?: string;
  confirmationButtonClick?: () => void;
  name?: string;
  showIcon?: boolean;
}>;
export function SuccessModal({
  isOpen,
  toggle,
  title,
  message,
  closeButtonText,
  name,
  showConfirmationButton = false,
  showIcon = false,
  confirmationButtonClick,
  confirmationButtonText,
  children,
}: SuccessModalProps) {
  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
      <ModalHeader className="border-0" close={<Close onClick={toggle} />} />
      {showIcon && (
        <div className="position-relative align-self-center mb-3">
          <Rocket48 className="mb-2 bg-success-subtle rounded-circle" />
          <Check16 className="position-absolute chip-solid-success rounded-circle border-0 bottom-0 end-0" />
        </div>
      )}
      <ModalBody className="p-0 x5-mx-8 mb-7">
        <h4 className="fw-light">{title}</h4>
        <p className="fw-light">{message}</p>
        {children}
      </ModalBody>
      <ModalFooter className="px-4 d-block text-center d-flex gap-2">
        <Button
          data-testid={`${textToSlug(name)}-button-close-dialog`}
          onClick={toggle}
          type="button"
          name="dialog-close-button"
          level="tertiary"
          theme="standard"
          rounded="pill"
        >
          {closeButtonText}
        </Button>
        {showConfirmationButton && (
          <Button
            data-testid={`${textToSlug(name)}-button-confirmation-dialog`}
            onClick={confirmationButtonClick}
            type="button"
            className="x5-me-1"
            name="dialog-close-button"
            level="primary"
            theme="standard"
            rounded="pill"
          >
            {confirmationButtonText}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
