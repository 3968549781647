import * as React from "react";
import { SVGProps } from "react";
const Light = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    aria-hidden="true"
    viewBox="0 0 15 15"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m8.335.047-.15-.015a7.499 7.499 0 1 0 6.14 10.577c.103-.229-.156-.447-.386-.346a5.393 5.393 0 0 1-.771.27A5.356 5.356 0 0 1 9.153.691C9.37.568 9.352.23 9.106.175a7.545 7.545 0 0 0-.77-.128zM6.977 1.092a6.427 6.427 0 0 0 5.336 10.671A6.427 6.427 0 1 1 6.977 1.092z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14.998v15H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Light;
