import { useQuery } from "@apollo/client";
import { GET_PRODUCT_BY_ID } from "../../../api/products/gql";

export function useSelectedProduct({ productId }: { productId: string }) {
  const { data, refetch, loading, error } = useQuery(GET_PRODUCT_BY_ID, {
    variables: { productId },
    // Skip the query if no valid productId is provided.
    skip: productId === "",
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  return { data, refetch, loading, error };
}
