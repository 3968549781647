import { createContext, useContext, useState, useMemo, ReactNode } from "react";
import { useUser } from "../hooks/use-user";
import { useUserProducts } from "../views/profile/hooks/use-user-products";
import { UserProductsQuery } from "../gql/graphql";
import { sortAndPrioritizeProducts } from "../views/profile/products/utils/product-utils";

// Define a Product type based on the GraphQL query.
export type ProductType = NonNullable<UserProductsQuery["currentUserProfile"]["products"]>[number];

type ProductContextType = {
  myProductsList: ProductType[];
  loading: boolean;
  refetchUser?: CallableFunction;
  refetch: CallableFunction;
  selectedProduct?: ProductType;
  setSelectedProduct: (product: ProductType) => void;
  starredProductId?: string | null;
};

const ProductContext = createContext<ProductContextType>({
  myProductsList: [],
  loading: true,
  refetchUser: () => {},
  refetch: () => {},
  selectedProduct: undefined,
  setSelectedProduct: () => {},
  starredProductId: null,
});

export function ProductProvider({ children }: { children: ReactNode }) {
  const user = useUser();
  const refetchUser = user?.refetch;
  const starredProductId = user?.starredProductId;

  const { data, refetch, loading } = useUserProducts();
  const userProducts = data?.currentUserProfile?.products || [];

  // Use the helper to filter, sort, and prioritize products.
  const myProductsList = useMemo(
    () => sortAndPrioritizeProducts(userProducts, user?.email, starredProductId),
    [userProducts, user?.email, starredProductId],
  );

  const [selectedProduct, setSelectedProduct] = useState<ProductType | undefined>(undefined);

  const contextValue = useMemo(
    () => ({
      myProductsList,
      loading,
      refetchUser,
      refetch,
      selectedProduct,
      setSelectedProduct,
      starredProductId,
    }),
    [myProductsList, loading, refetchUser, refetch, selectedProduct, starredProductId],
  );

  return <ProductContext.Provider value={contextValue}>{children}</ProductContext.Provider>;
}

export function useProductContext() {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("useProductContext must be used within a ProductProvider");
  }
  return context;
}
